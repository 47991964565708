<template>
  <div>
    <vs-card>
      <vs-tabs
        alignment="fixed"
        color="primary"
        v-model="index"
        v-if="configuration.length > 0"
        ref="vsTabs"
      >
        <vs-tab
          v-for="tab in configuration"
          :key="tab.group"
          :label="tab.group"
          icon-pack="feather"
        >
          <vs-row>
            <vs-col
              v-for="(config, index) in tab.configurations"
              :key="config.id"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              :vs-w="getColWidth(tab.configurations.length, index)"
              class="p-2"
            >
              <component
                :is="config.componentType"
                @configUpdated="configUpdated"
                :item="config"
              />
            </vs-col>
          </vs-row>
        </vs-tab>
      </vs-tabs>
    </vs-card>
  </div>
</template>

<script>
import {SharedRepositoryFactory} from "../shared-repositories/sharedRepositoryFactory";
import _ from "lodash";

const configRepo = SharedRepositoryFactory.get('configRepository');
export default {
  name: "Configuration",
  components: {
    file: () => import('../shared-components/configComponents/FileInputConfig'),
    tmText: () => import('../shared-components/configComponents/TextInputConfig'),
    number: () => import('../shared-components/configComponents/NumberInputConfig'),
    toggle: () => import('../shared-components/configComponents/ToggleConfig'),
    radio: () => import('../shared-components/configComponents/RadioConfig'),
    tmSelect: () => import('../shared-components/configComponents/SelectConfig'),
    checkbox: () => import('../shared-components/configComponents/CheckboxConfig')
  },
  data() {
    return {
      isLoading: true,
      configuration: [],
      index: -1
    };
  },
  methods: {
    async fetchConfig(module) {
      const response = await configRepo.getAll(`?filter[module]=${module}`);
      this.configuration = _.chain(response)
        .groupBy('group').map((value, key) => ({
          group: key,
          configurations: value
        })).value();
    },
    async configUpdated(item) {
      const res = await configRepo.updateConfig(this.$objectToFD({value: item.value}), item.id);
      item.value = res.value;
    },
    getColWidth(length, index) {
      if (length % 2 === 1 && length - 1 === index)
        return '12';
      return '6';
    }
  },
  created() {
    this.fetchConfig(this.$route.params.key);
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.configuration = [];
        await $this.fetchConfig(this.$route.params.key);
      },
      {immediate: false}
    );
  }
};
</script>
